<template>
  <div class="template-carousel">
    <div v-if="loading" class="template-carousel__loading">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <div v-else class="template-carousel__items">
      <div class="carousel__delimiter">
        <v-btn icon class="carousel__delimiter-arrow" @click="prev">
          <v-icon>$chevron_left</v-icon>
        </v-btn>
        <div class="carousel__delimiter-circle">
          <v-icon
            v-for="(item, index) in itemList"
            :key="index"
            size="8"
            :class="{ 'active-circle': index === carousel }"
            @click="goTo(index)"
          >
            $dot
          </v-icon>
        </div>
        <v-btn class="carousel__delimiter-arrow" icon @click="next">
          <v-icon>$chevron_right</v-icon>
        </v-btn>
      </div>
      <v-carousel
        v-model="carousel"
        hide-delimiters
        height="auto"
        show-arrows-on-hover
      >
        <v-carousel-item v-for="(item, index) in itemList" :key="index">
          <v-row>
            <v-col
              v-for="(template, templateIndex) in item"
              :key="templateIndex"
              cols="12"
              sm="6"
              md="4"
            >
              <template-item v-model="selected" :item="template" />
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateCarousel",
  components: {
    TemplateItem: () => import("./TemplateItem"),
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    carousel: 0,
    itemList: [],
    selected: {},
  }),
  computed: {
    numberOfItemsInColumn() {
      let n = 3;

      if (this.$vuetify.breakpoint.smAndDown && !this.$vuetify.breakpoint.sm) {
        n = 1;
      } else if (
        this.$vuetify.breakpoint.smAndUp &&
        !this.$vuetify.breakpoint.mdAndUp
      ) {
        n = 2;
      }

      return n;
    },
  },
  watch: {
    items() {
      this.itemList = this.generateItems();
    },
    selected() {
      this.$emit("change", this.selected);
    },
  },
  mounted() {
    this.itemList = this.generateItems();
  },
  created() {
    window.addEventListener("resize", this.resizeEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEventHandler);
  },
  methods: {
    resizeEventHandler() {
      this.itemList = this.generateItems();
    },
    generateItems() {
      const n = this.numberOfItemsInColumn;
      const items = this.items.slice().reverse();
      return new Array(Math.ceil(items.length / n))
        .fill()
        .map((_) => items.splice(0, n));
    },
    prev() {
      if (this.carousel === 0) {
        this.carousel = this.itemList.length - 1;
        return;
      }
      this.carousel--;
    },
    next() {
      if (this.carousel === this.itemList.length - 1) {
        this.carousel = 0;
        return;
      }
      this.carousel++;
    },
    goTo(index) {
      this.carousel = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.template-carousel__loading {
  display: flex;
  justify-content: center;
}

.carousel__delimiter {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.carousel__delimiter-arrow {
  color: var(--v-fourthary-base);
}

.carousel__delimiter-circle .v-icon {
  margin: 0 4px;
  color: var(--v-fourthary-base);
  opacity: 0.24;

  &:hover {
    cursor: pointer;
  }
}

.carousel__delimiter-circle .v-icon.active-circle {
  color: var(--v-secondary-base);
  opacity: 1;
}
</style>
